
/**
 * 配置
 */
const CONFIG = {
    development: {
        baseApiUrl: "http://127.0.0.1:5082",
        // baseApiUrl: "https://user-api.blizzardpay.pw",
        baseImageUrl: "http://127.0.0.1:5082"
    },
    production: {
        // baseApiUrl: "//user-api.blizzardpay.pw",
        // baseImageUrl: "//user-api.blizzardpay.pw"
         baseApiUrl: "//user-api.xpay.pw",
        baseImageUrl: "//user-api.xpay.pw"
    }
}

const config = CONFIG[`${process.env.NODE_ENV}`]

export default config;


const SYS_CONFIG = {
    sysName: "",
    sysLogo: ""
};

export const getSysConfig = () => {
    return SYS_CONFIG;
};

export const initSysConfig = (values) => {

    Object.keys(values).forEach(k => SYS_CONFIG[`${k}`] = values[`${k}`]);

    // document.title = values["sysName"];
    // document.getElementById("logo-ico").setAttribute("href", `${config.baseImageUrl}/${values["sysLogo"]}`)

    return SYS_CONFIG;
}

